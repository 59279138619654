/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";
import {
  Clouds,
  CloudWrapper,
  StyledCloud,
} from "@/components/LandingPages/LandingHeading.styles";
import Image from "next/image";
import Link from "next/link";

import { getContextForApp } from "@fllite/shared";
import { muiTheme } from "@fllite/ui/themes";

import { ReadMoreLink, SubTitle } from "@/components/PageIndex/shared";

const ContentWrapper = styled("article")`
  position: relative;
  min-width: 100%;
  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    position: relative;
    text-align: initial;
    min-height: 800px;
  }
`;

const Content = styled("article")`
  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    max-width: 450px;
    margin-top: 80px;
  }
`;

const Paragraph = styled("p")`
  color: ${({ theme }) => (theme as any).colors.baseFontColor};
  font-size: 20px;
  margin-bottom: 32px;
  line-height: 4.6rem;
  text-align: left;
`;

const RequestAFlightButtonWrapper = styled("div")`
  text-align: left;
  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    text-align: center;
    margin-top: 48px;
    text-align: left;
  }
`;

const PromoImg = styled(Image)`
  width: 40vw;
  position: relative;
  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    width: 100%;
    max-width: 293px;
    max-height: 315px;
    position: absolute;
  }
`;

const PromoImg1 = styled(PromoImg)`
  width: 90vw;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    left: 20px;
    top: 0px;
    width: 304px;
    height: 320px;
    top: 20px;
    left: auto;
    right: 25px;
  }
`;

const PromoImg2 = styled(PromoImg)`
  width: 90vw;
  margin-top: 24px;
  max-width: 293px;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    margin-top: 48px;
    margin-bottom: 250px;
    margin: 0;
    right: 84px;
    width: 388px;
    height: 315px;
    top: 315px;
  }
`;

export const Description = () => {
  const isDesktopMatch = useMediaQuery(muiTheme.breakpoints.up("sm"));
  const { appUrl } = getContextForApp();

  return (
    <ContentWrapper>
      {isDesktopMatch && (
        <PromoImg1
          src="/assets/example-ticket-02.webp"
          width={293}
          height={315}
          alt="Ticket"
        />
      )}
      <Content>
        <SubTitle data-testid="title-comfort-zone">
          Fly in your comfort zone
        </SubTitle>
        <Paragraph data-testid="paragraph-comfort">
          We simplify the private jet booking process, eliminating the need for
          time-consuming emails and approvals. Get access to thousands of
          charter flights and finalize your booking quickly and efficiently.
        </Paragraph>

        <RequestAFlightButtonWrapper>
          <Link href={`${appUrl}/register`} passHref legacyBehavior>
            <ReadMoreLink data-testid="homepage-register-link">
              Create your free account
            </ReadMoreLink>
          </Link>
        </RequestAFlightButtonWrapper>
      </Content>
      <PromoImg2
        src="/assets/example-ticket-03.webp"
        width={293}
        height={315}
        alt="Ticket"
      />
      {isDesktopMatch && (
        <Clouds>
          <CloudWrapper style={{ left: "-70%", marginTop: "-8%" }}>
            <StyledCloud style={{ width: "1000px" }} />
          </CloudWrapper>
          <CloudWrapper style={{ left: "70%", marginTop: "-18%" }}>
            <StyledCloud style={{ width: "1000px" }} />
          </CloudWrapper>
        </Clouds>
      )}
    </ContentWrapper>
  );
};
