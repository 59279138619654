/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useMemo } from "react";

import styled from "@emotion/styled";
import { Box, useMediaQuery } from "@mui/material";
import { RequestAFlightButton } from "@/components/LandingPages/RequestAFlightButton";
import Image from "next/image";

import { muiTheme } from "@fllite/ui/themes";

import { SubTitle } from "@/components/PageIndex/shared";

const CustomSubTitle = styled(SubTitle)`
  text-align: center;
  color: ${({ theme }) => (theme as any).colors.primaryDarkest};
`;

const Wrapper = styled.div``;

const Images = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 32px;
`;

interface DestinationItemProps {
  id: string;
  title: string;
  imageUrl: string;
}

const destinations = [
  {
    id: "SDL -> LAX",
    title: "SDL -> LAX",
    imageUrl: "/assets/homepage/popular-destinations-001.webp",
  },
  {
    id: "DFW -> ASE",
    title: "DFW -> ASE",
    imageUrl: "/assets/homepage/popular-destinations-002.webp",
  },
  {
    id: "APA -> LAS",
    title: "APA -> LAS",
    imageUrl: "/assets/homepage/popular-destinations-003.webp",
  },
  {
    id: "PDX -> TEX",
    title: "PDX -> TEX",
    imageUrl: "/assets/homepage/popular-destinations-004.webp",
  },
  {
    id: "LAX -> SFO",
    title: "LAX -> SFO",
    imageUrl: "/assets/homepage/popular-destinations-005.webp",
  },
] as DestinationItemProps[];

export const PopularDestinations: FC = () => {
  const isDesktopMatch = useMediaQuery(muiTheme.breakpoints.up("md"));

  const selectedDestinations = useMemo(
    () => [...destinations].slice(0, isDesktopMatch ? destinations.length : 2),
    [isDesktopMatch],
  );

  return (
    <Wrapper>
      <CustomSubTitle>Popular Destinations</CustomSubTitle>
      <Images>
        {selectedDestinations.map((destination) => (
          <Image
            key={destination.id}
            src={destination.imageUrl}
            alt={destination.title}
            width={isDesktopMatch ? 235 : 157}
            height={isDesktopMatch ? 216 : 144}
            quality={100}
          />
        ))}
      </Images>
      <Box sx={{ width: "100%", textAlign: "center", mb: 2 }}>
        <RequestAFlightButton />
      </Box>
    </Wrapper>
  );
};
