/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";

import styled from "@emotion/styled";
import { Avatar, Rating, Stack, Typography } from "@mui/material";
import Image from "next/image";

import { FeedbackContent } from "./Feedback";

interface WrapperProps {
  isFocused: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: ${({ isFocused }) => (isFocused ? 1 : 0)};
  transition:
    opacity 0.3s,
    transform 0.3s;
  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    flex-direction: row;
  }
`;

const ImageBox = styled(Image)`
  border-radius: ${({ theme }) => (theme as any).radius.smallBoxRadius};
  width: 100%;
  height: auto;
  position: relative;
  z-index: -2;
  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    width: 50%;
    position: relative;
    left: 32px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${({ theme }) => (theme as any).radius.smallBoxRadius};
  background-color: white;
  padding: 32px 24px;
  position: relative;
  margin-top: -8px;
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: calc(100% + 6px);
    background: linear-gradient(90deg, #ec5f00 0%, #ff9100 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: ${({ theme }) => (theme as any).radius.boxRadius};
  }
  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    width: 50%;
    margin: 68px 0 68px -32px;
  }
`;

const Content = styled(Typography)`
  font-size: 18px;
  line-height: 3.8rem;
`;

const AuthorName = styled(Typography)`
  font-weight: bold;
  font-size: 16px;
`;

const FlyType = styled(Typography)`
  color: #97989a;
  font-size: 14px;
`;

interface FeedbackItemProps {
  feedbackItem: FeedbackContent;
  isFocused: boolean;
}

export const FeedbackItem: FC<FeedbackItemProps> = ({
  feedbackItem,
  isFocused,
}) => (
  <Wrapper isFocused={isFocused}>
    <ImageBox
      src={feedbackItem.airplanePhotoUrl}
      alt="Airplane photo"
      width={450}
      height={300}
    />
    <ContentWrapper>
      <Content>“{feedbackItem.content}”</Content>
      {feedbackItem.stars && (
        <Rating name="read-only" value={feedbackItem.stars} readOnly />
      )}
      <Stack direction="row" spacing={2} alignItems="center">
        {feedbackItem.authorPhotoUrl && (
          <Avatar
            alt={feedbackItem.authorName}
            src={feedbackItem.authorPhotoUrl}
            sx={{ width: "48px", height: "48px" }}
          />
        )}
        <AuthorName>{feedbackItem.authorName}</AuthorName>
      </Stack>
      <FlyType>{feedbackItem.flyType}</FlyType>
    </ContentWrapper>
  </Wrapper>
);
