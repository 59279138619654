/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from "react";

import styled from "@emotion/styled";
import {
  Form,
  HeadText,
  SubTitle,
  Title,
} from "@/components/LandingPages/LandingHeading.styles";
import { useRouter } from "next/router";

import { getContextForApp } from "@fllite/shared";
import { useAuthentication } from "@fllite/ui/hooks";
import { RequestAFlightApp } from "@fllite/ui/components";
import { RaF } from "@fllite/ui/components";
import { saveRafFormValues } from "@fllite/ui/utils";

import { Row, RowContent } from "@/components/PageIndex/shared";

const CustomRow = styled(Row)`
  background: rgba(0, 37, 83, 0.7);
  background: transparent url("/assets/hp-header-background.webp") top center
    no-repeat;
  background-size: cover;

  ${({ theme }) => (theme as any).breakpoints.up("sm")} {
    margin-top: 52px;
    padding: 28px;
  }

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    padding: 48px 20px 80px;
  }

  &::after {
    background: linear-gradient(
      3deg,
      #002553 26.16%,
      rgba(0, 37, 83, 0) 60.28%
    );
    content: "";
    position: absolute;
    bottom: 0;
    height: 30%;
    width: 100%;
  }
`;

export const WelcomeForm: FC = () => {
  const { push } = useRouter();
  const { user } = useAuthentication();
  const [isRequestingFlight, setIsRequestingFlight] = useState(false);
  const { appUrl } = getContextForApp();

  const handleFormSubmit = (values: any) => {
    setIsRequestingFlight(true);
    saveRafFormValues(values);
    push(`${appUrl}/request-a-flight`);
  };

  return (
    <CustomRow>
      <RowContent>
        <HeadText>
          <Title data-testid="h1-title">
            Book a private aircraft in minutes
          </Title>
          <SubTitle data-testid="p-4step" color="">
            With Fllite, you can book charter flights in just 4 easy steps.
          </SubTitle>
        </HeadText>
        <Form>
          {user !== false && user != null ? (
            <RequestAFlightApp isHomepage />
          ) : (
            <RaF
              onSubmit={handleFormSubmit}
              isLoading={isRequestingFlight}
              isHomepage
            />
          )}
        </Form>
      </RowContent>
    </CustomRow>
  );
};
