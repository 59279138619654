import { FC } from "react";

import { styled } from "@mui/material";

import About from "./About";
import AircraftOwnership from "./AircraftOwnership";
import { Claim } from "./Claim";
import { PopularDestinations } from "./PopularDestinations";
import { Row, RowContent } from "@/components/PageIndex/shared";

const CustomRow = styled(Row)`
  background: linear-gradient(
    180deg,
    #edf0f4 0%,
    #edf0f4 180px,
    #00316e 180px,
    #00316e 100%
  );
`;

export const Footer: FC = () => (
  <CustomRow>
    <RowContent>
      <PopularDestinations />
      <About />
      <Claim />
      <AircraftOwnership />
    </RowContent>
  </CustomRow>
);
