/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "@emotion/styled";

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  position: relative;
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: ${({ theme }) => (theme as any).dimensions.pageWidth};
  width: 100%;
  position: relative;
  z-index: 10;
`;

export const SubTitle = styled("h2")`
  color: ${({ theme }) => (theme as any).colors.baseFontColor};
  font-size: 24px;
  margin-bottom: 0;
  line-height: 5.2rem;
  text-align: left;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    margin-bottom: 32px;
    font-size: 36px;
  }
`;

export const ReadMoreLink = styled("a")`
  white-space: nowrap;
  border: 0 none;
  color: white;
  border: 1px solid white;
  border-radius: ${({ theme }) => (theme as any).radius.buttonRadius};
  padding: 8px 32px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => (theme as any).colors.primary};
    color: ${({ theme }) => (theme as any).colors.baseFontColor};
  }
`;
